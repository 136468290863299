import React, { useEffect, useState } from "react";
import "./routes.css";
import Route from "./Route";
import { useTranslation } from "react-i18next";
import { PRO_URL } from "../../api";

const Routes = () => {
  const { t } = useTranslation();
  const [routes, setRoutes] = useState([]); // Estado para los datos
  const [loading, setLoading] = useState(true); // Estado para indicar carga
  const [error, setError] = useState(null); // Estado para manejar errores

  useEffect(() => {
    // Función para obtener los paquetes
    const fetchRoutes = async () => {
      try {
        const response = await fetch(`${PRO_URL}/api/routes/`);
        if (!response.ok) {
          throw new Error(`Error en la solicitud: ${response.status}`);
        }
        const data = await response.json(); // Parsear los datos
        setRoutes(data); // Guardar los datos en el estado
      } catch (err) {
        setError(err.message); // Capturar el error
      } finally {
        setLoading(false); // Marcar la carga como completada
      }
    };

    fetchRoutes();
  }, []); // [] asegura que se ejecuta solo al montar el componente

  // Mostrar mensaje mientras los datos cargan
  if (loading) return <p>{t("api.response.loading")}</p>;

  // Mostrar error si ocurre
  if (error)
    return (
      <p>
        {t("api.response.error")}
        {error}
      </p>
    );
  return (
    <section id="routes" className="routes-container">
      <h2 className="routes-container-title">{t("route.container.title")}</h2>
      <div className="route-container">
        {routes.map((route) => (
          <Route key={route.id} route={route} />
        ))}
      </div>
    </section>
  );
};

export default Routes;
