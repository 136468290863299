import React, { useEffect, useState } from "react";
import "../BookForm.css";
import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa6";
import { useBooking } from "../../../providers/BookProvider";
import PackageModule from "../../package/PackageModule";
import LeftForm from "./LeftForm";
import { handleServiceSelection, getPrices, showServiceExtra } from "../utils/functions";
import BookFormLeftFieldSelect from "./BookFormLeftFieldSelect";
import ServicesInfo from "../../services-specs/ServicesInfo";
import { PRO_URL } from "../../../api";

const BookFormLeft = ({ type }) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { selectedPackage, setFormData, formData, selectedRoute, isBookRouteModalOpen } = useBooking();
  const [isServicesInfoOpen, setIsServicesInfoOpen] = useState(false);
  const [servicesIncluded, setIncludedServices] = useState(null);
  const [servicesExtra, setExtraServices] = useState(null);
  const [isServicesOpen, setIsServicesOpen] = useState(true);

  useEffect(() => {
    if (selectedPackage) {
      fetch(`${PRO_URL}/api/get-package-services/?id=${selectedPackage.id}`) // URL de tu endpoint
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setIncludedServices(data.included_services);
          setExtraServices(data.extra_services);
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
    }
  }, [selectedPackage]);

  const closeServicesModal = () => setIsServicesInfoOpen(false);
  const bikeIncluded = [9, 10, 11, 12, 13];
  return (
    <div className="book-form-left-content">
      {type === "package" && <BookFormLeftFieldSelect />}
      {type !== "package" && <PackageModule isForm={true} />}
      <div className="book-form-left-contact-module">
        {servicesIncluded !== null && isServicesOpen && (
          <div className="book-form-left-content-start">
            <label className="book-form-left-label">{t("book.form.service.included")}</label>
            <div className="book-form-sevices-extra">
              {servicesIncluded.map((service) => {
                const serviceName = currentLang === "en" ? service.name_en : service.name;

                // Si el servicio tiene id 21, solo mostrarlo si la ruta seleccionada está en bikeIncluded
                if (service.id === 21 && !bikeIncluded.includes(selectedRoute?.id)) {
                  return null; // No renderizar nada si la ruta no está en bikeIncluded
                }

                return (
                  <div key={service.id} className="book-form-services-check">
                    <span>
                      <FaCheck className="book-form-services-icon" />
                    </span>
                    {serviceName}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {servicesExtra !== null && isServicesOpen && (
          <div className="book-form-left-content-end">
            <label className="book-form-left-label-extra-service">{t("book.form.service.extra")}</label>
            <p className="book-service-extra-price-sutitle">{t("book.form.service.extra.price.day")}</p>
            <p className="book-service-extra-price-sutitle">*{t("book.form.service.extra.price.four.person")}</p>
            <p className="book-service-extra-price-sutitle">**{t("book.form.service.extra.price.not.included")}</p>
            <div className="book-form-sevices-extra">
              {servicesExtra.map((service) => {
                const serviceName = currentLang === "en" ? service.name_en : service.name;
                return (
                  showServiceExtra(service.id, selectedRoute?.id) && (
                    <div key={service.id} className="book-form-services-check">
                      <input
                        type="checkbox"
                        className="boook-form-services-check-input"
                        onChange={() => handleServiceSelection(service, formData, setFormData)}
                      />
                      <div className="book-form-services-check-container">
                        <div className="check-service-name">{serviceName}</div>
                        <div className="check-service-price">{`${getPrices(
                          service.price,
                          service.id,
                          selectedRoute?.stages?.length ?? 0,
                          selectedRoute?.id,
                          selectedPackage.id
                        )} €`}</div>
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </div>
        )}
      </div>
      <span className="book-form-left-hide-button" onClick={() => setIsServicesOpen(!isServicesOpen)}>
        {isServicesOpen ? `${t("book.form.button.hide")}` : `${t("book.form.button.show")}`}
      </span>
      <span className="book-form-left-hide-button" style={{ marginTop: "10px" }} onClick={() => setIsServicesInfoOpen(!isServicesInfoOpen)}>
        {t("book.form.button.services.info")}
      </span>
      {isServicesInfoOpen && <ServicesInfo closeServicesModal={closeServicesModal} />}
      <LeftForm />
    </div>
  );
};

export default BookFormLeft;
