import React, { useEffect, useState } from "react";
import "./Package.css";
import { useBooking } from "../../providers/BookProvider";
import BookForm from "../book-form/BookForm";
import { getBookRoutePrice } from "../book-form/utils/functions";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ServiceInfoModal from "../services-specs/ServiceInfoModal";
const Package = ({ pckg, isForm }) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { selectedPackage, setSelectedPackage, setFormData, isBookPackageModalOpen, openPackageForm, closeForm } = useBooking();

  const isSelected = selectedPackage?.id === pckg.id;

  const handleSelect = () => {
    setSelectedPackage(pckg);
    setFormData((prev) => ({ ...prev, package: pckg.id }));

    !isForm && openPackageForm();
  };

  useEffect(() => {
    const handlePopState = () => {
      closeForm();
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState); // Limpieza importante
    };
  }, []);

  const [modalPetOpen, setModalPetOpen] = useState(false);
  const [typeServiceModal, setTypeServiceModal] = useState("");

  const closeServiceModal = () => {
    setModalPetOpen(false);
  };

  const description = currentLang === "en" ? pckg.description_en : pckg.description;
  const pckgName = currentLang === "en" ? pckg.name_en : pckg.name;
  return (
    <div className={`${isForm ? "" : `package-content-image-bcgd ${pckg.id === 1 ? "classic" : "basic"}`}`}>
      <div className={`package-content ${isSelected && isForm ? "selected" : "image"}`}>
        <div className="package-title-container">
          <div className={`package-title ${isSelected && isForm}`}>{pckgName}</div>
          <div className="package-price">{`${t("package.price.from")} ${Math.round(pckg.price)} €`}</div>
        </div>
        <div className={`package-description ${isSelected && isForm}`}>{description}</div>
        <div title={t("package.modal.opt.bike.info")} className="package-opt-shown">
          {t("package.modal.opt.bike")}
        </div>
        {pckg.id === 1 && (
          <Link to="/sevice-info-open">
            <div
              onClick={() => {
                setModalPetOpen(true);
                setTypeServiceModal("pet");
              }}
              className="package-opt-shown"
            >
              {t("package.modal.opt.pet")}
            </div>
          </Link>
        )}
        <Link to="/book-form">
          <button className="package-button" onClick={handleSelect}>
            {t("route.modal.button.book")}
          </button>
        </Link>
        {modalPetOpen && <ServiceInfoModal closeServiceModal={closeServiceModal} type={typeServiceModal} />}
        {isBookPackageModalOpen && <BookForm type={"package"} />}
      </div>
    </div>
  );
};

export default Package;
