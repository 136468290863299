import React, { useEffect } from "react";
import "./locales/i18n";
import "./App.css";
import Layout from "./layout/layout";
import { NotificationProvider } from "./providers/NotificationProvider";
import { BookingProvider } from "./providers/BookProvider";
import CookieConsent from "react-cookie-consent";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import PoliticaCookies from "./components/textos-politicas/PoliticaCookies";
import PoliticaPrivacidad from "./components/textos-politicas/PoliticaPrivacidad";
import TerminosCondiciones from "./components/textos-politicas/TerminosCondiciones";
import CancelacionReembolsos from "./components/textos-politicas/CancelacionReembolsos";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import { useCookies } from "./providers/CookiesProvider";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);
const App = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const { cookieConsent, acceptCookies, declineCookies } = useCookies();

  window.onload = function () {
    if (window.devicePixelRatio > 1) {
      document.body.style.zoom = isMobile ? "60%" : "100%"; // Asegura que el zoom no cambie
    }
  };

  return (
    <Router>
      <Elements stripe={stripePromise}>
        <NotificationProvider>
          <BookingProvider>
            <div className="app">
              {cookieConsent === null && (
                <CookieConsent
                  location="bottom"
                  buttonText="Aceptar Necesarias"
                  declineButtonText="Rechazar"
                  enableDeclineButton
                  cookieName="cookies_consent"
                  onAccept={acceptCookies}
                  onDecline={declineCookies}
                  style={{ background: "#011d5f" }}
                  buttonStyle={{
                    color: "#011d5f",
                    fontSize: "ffdd58",
                    textTransform: "uppercase",
                  }}
                  declineButtonStyle={{
                    color: "#fff",
                    background: "#c33",
                    textTransform: "uppercase",
                  }}
                >
                  {t("cookies.module.description")}{" "}
                  <Link to="/politica-de-cookies" style={{ color: "#ffffff", textDecoration: "underline" }}>
                    {t("cookies.module.read.more")}
                  </Link>
                </CookieConsent>
              )}

              {/* Sistema de rutas */}
              <Routes>
                <Route path="/" element={<Layout />} />
                <Route path="/more-info-rutes" element={<Layout />} />
                <Route path="/more-info-services" element={<Layout />} />
                <Route path="/contact-form" element={<Layout />} />
                <Route path="/book-form" element={<Layout />} />
                <Route path="/sevice-info-open" element={<Layout />} />
                <Route path="/politica-de-cookies" element={<PoliticaCookies />} />
                <Route path="/politica-de-privacidad" element={<PoliticaPrivacidad />} />
                <Route path="/politica-de-cancelacion-reembolso" element={<CancelacionReembolsos />} />
                <Route path="/politica-de-terminos-condiciones" element={<TerminosCondiciones />} />
              </Routes>
            </div>
          </BookingProvider>
        </NotificationProvider>
      </Elements>
    </Router>
  );
};

export default App;
