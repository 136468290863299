import { PRO_URL } from "../../../api";

export const onSubmit = async (userInfo, closeForm, showNotification, t) => {
  try {
    const response = await fetch(`${PRO_URL}/api/send-contact-form/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userInfo),
    });

    if (response.ok) {
      showNotification(t("contact.form.success"), "success");
      closeForm(); // Cerrar el formulario al enviar
    } else {
      const errorData = await response.json();
      showNotification(`ERROR: ${errorData.error}`, "error");
    }
  } catch (error) {
    console.error("Error al enviar el formulario:", error);
    showNotification(t("contact.form.error"), "error");
  }
};

export const handleInputChange = (e, setCharCount) => {
  setCharCount(e.target.value.length);
};
