import React from "react";
import "./cookies-module.css";
import { useTranslation } from "react-i18next";

const PoliticaPrivacidad = () => {
  const { t } = useTranslation();
  return (
    <div className="legal-text">
      <h1>{t("text.policy.privacy.title")}</h1>
      <p>{t("text.policy.cancel.update")} 25/11/2024</p>
      <p>
        {t("text.policy.cookies.description.p1")} <strong>CAMINO PEREGRINO</strong>, {t("text.policy.privacy.description")}
      </p>
      <h2>{t("text.policy.privacy.clause1.title")}</h2>
      <p>
        {t("text.policy.privacy.clause1.description1")} <strong>CAMINO PEREGRINO</strong>. {t("text.policy.privacy.clause1.description2")}{" "}
        <a href="mailto:caminoperegrinoo@gmail.com">caminoperegrinoo@gmail.com</a>.
      </p>
      <h2>{t("text.policy.privacy.clause2.title")}</h2>
      <p>{t("text.policy.privacy.clause2.description1")}</p>
      <ul>
        <li>{t("text.policy.privacy.clause2.description1.data1")}</li>
        <li>{t("text.policy.privacy.clause2.description1.data2")}</li>
        <li>{t("text.policy.privacy.clause2.description1.data3")}</li>
      </ul>
      <p>{t("text.policy.privacy.clause2.description1.subtitle")}</p>
      <h2>{t("text.policy.privacy.clause3.title")}</h2>
      <p>{t("text.policy.privacy.clause3.description")}</p>
      <ul>
        <li>{t("text.policy.privacy.clause3.description.p1")}</li>
        <li>{t("text.policy.privacy.clause3.description.p2")}</li>
        <li>{t("text.policy.privacy.clause3.description.p3")}</li>
        <li>{t("text.policy.privacy.clause3.description.p4")}</li>
      </ul>
      <h2>{t("text.policy.privacy.clause4.title")}</h2>
      <p>{t("text.policy.privacy.clause4.description")}</p>
    </div>
  );
};

export default PoliticaPrivacidad;
