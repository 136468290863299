import React, { useEffect, useState } from "react";
import "../BookForm.css";
import { useTranslation } from "react-i18next";
import { PRO_URL } from "../../../api";
import {
  getPrice,
  getTotalDays,
  getTotalPrice,
  totalPrice,
  calculateEndDate,
  getTotalPriceExtraNights,
  onBookSubmit,
  getTotalStripePrice,
  getPrices,
  has20PersentAble,
  getPricePerExtraService,
  getTotalPartPrice,
  getTotalPartStripePrice,
} from "../utils/functions";
import { useBooking } from "../../../providers/BookProvider";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import BookFormRightField from "./BookFormRightField";
import BookFormRightPrice from "./BookFormRightFieldPrice";
import PriceField from "./PriceField";
import { useNotification } from "../../../providers/NotificationProvider";
import BookFormRightFromRoute from "./BookFormRightFromRoute";
import BookFormRightFromPackage from "./BookFormRightFromPackage";
import BookFormRightFieldLong from "./BookFormRightFieldLong";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
console.log(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);
const BookFormRight = ({ type }) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { showNotification } = useNotification();
  const { selectedPackage, formData, selectedRoute, isFormValid, closeForm } = useBooking();

  const handleCheckout = async (isPart) => {
    try {
      const price = !isPart
        ? getTotalStripePrice(
            selectedPackage.price,
            totalPrice(formData, selectedRoute, selectedPackage),
            getTotalPriceExtraNights(selectedPackage.extra_night || 0, formData.extra_nights || 0),
            formData.num_people
          )
        : getTotalPartStripePrice(
            selectedPackage.price,
            totalPrice(formData, selectedRoute, selectedPackage),
            getTotalPriceExtraNights(selectedPackage.extra_night || 0, formData.extra_nights || 0),
            formData.num_people
          ); // Ejemplo: 1000 centavos (10 EUR)
      console.log(formData.extraServices.map((service) => service.id));
      const response = await fetch(`${PRO_URL}/api/create-checkout-session/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: price,
          client_name: formData.name,
          client_email: formData.email,
          client_number: formData.phone,
          package: formData.package,
          route: formData.route,
          start_date: formData.start_date,
          extra_nights: formData.extra_nights,
          total_people: formData.num_people,
          additional_services: formData.extraServices.map((service) => service.name),
          total_price: formData.totalPrice,
        }),
      });

      const session = await response.json();
      // Paso 2: Usar el sessionId para redirigir al usuario a Stripe
      const stripe = await stripePromise;

      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (error) {
        console.error("Error al redirigir a Stripe Checkout:", error);
        showNotification("Hubo un error al iniciar el pago", "error");
      }
    } catch (error) {
      console.error("Error al crear la sesión de Stripe:", error);
      showNotification("Hubo un error al iniciar el pago", "error");
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get("session_id");

    /*if (sessionId) {
      // Llamar a tu backend para verificar el estado del pago con el sessionId
      fetch(`${PRO_URL}/api/check-payment-status/?session_id=${sessionId}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.payment_status === "succeeded") {
            // El pago fue exitoso, llamar a handlePaymentSuccess
            handlePaymentSuccess(formData, selectedPackage, closeForm, showNotification);
          } else {
            showNotification("El pago no se completó correctamente", "error");
          }
        })
        .catch((error) => {
          console.error("Error al verificar el estado del pago:", error);
          showNotification("Hubo un error al verificar el pago", "error");
        });
    }*/
  }, [formData, selectedPackage, closeForm, showNotification]);

  // Función para llamar a onBookSubmit solo si el pago es exitoso
  const handlePaymentSuccess = async () => {
    try {
      // Aquí llamas a onBookSubmit para guardar la reserva
      await onBookSubmit(
        formData,
        closeForm,
        showNotification,
        getTotalPrice(
          selectedPackage.price,
          totalPrice(formData, selectedRoute, selectedPackage),
          getTotalPriceExtraNights(selectedPackage.extra_night || 0, formData.extra_nights || 0),
          formData.num_people
        )
      );
    } catch (error) {
      console.error("Error al guardar la reserva:", error);
      showNotification("Hubo un error al guardar la reserva", "error");
    }
  };
  const selectedRoutePrice = selectedPackage.id !== 1 ? selectedRoute?.price_basic : selectedRoute?.price_classic;
  return (
    <Elements stripe={stripePromise}>
      <div className="book-form-right-content">
        <h2 className="book-form-right-title">{t("book.form.right.title")}</h2>
        {type === "package" && <BookFormRightFromPackage />}
        {type === "route" && <BookFormRightFromRoute />}
        {formData.extraServices.length > 0 && (
          <div className="book-form-right-field-service">
            <strong className="book-form-right-label-service">{`${t("book.form.service.extra")} :`}</strong>{" "}
            <div>
              {formData.extraServices.map((service) => (
                <PriceField
                  key={service.id}
                  name={currentLang === "en" ? service.name_en : service.name}
                  value={getPricePerExtraService(
                    service.price,
                    service.id,
                    selectedRoute?.stages.length ?? 0,
                    selectedRoute?.id,
                    selectedPackage.id,
                    formData.num_people
                  )}
                  isPackage={false}
                />
              ))}
            </div>
          </div>
        )}
        <BookFormRightFieldLong label={"book.form.service.name"} value={formData.name} />
        <div className="book-form-right-field-mail">
          <strong className="book-form-right-label-mail">{`${t("book.form.service.email")} :`}</strong>{" "}
          <p className="book-form-right-mail-placeholder">{formData.email || t("book.form.empty")}</p>
        </div>
        <BookFormRightField label={"book.form.service.phone"} value={formData.phone} />{" "}
        <BookFormRightField
          label={"book.form.service.start.date"}
          value={formData.start_date ? format(new Date(formData.start_date), "dd/MM/yyyy") : t("book.form.empty")}
        />
        {formData.extra_nights > 0 && (
          <BookFormRightPrice
            label={"book.form.nights.extra"}
            name={formData.extra_nights}
            value={getTotalPriceExtraNights(selectedPackage.extra_night || 0, formData.extra_nights || 0)}
            isPackage={false}
          />
        )}
        <BookFormRightField
          label={"book.form.end.date"}
          value={calculateEndDate(formData.start_date || "", getTotalDays(selectedRoute?.stages?.length ?? 0, formData.extra_nights))}
        />
        <BookFormRightField
          label={"book.form.dutation"}
          value={`${getTotalDays(selectedRoute?.stages?.length ?? 0, formData.extra_nights)}  ${t("book.form.dutation.nights")}`}
        />
        <BookFormRightField label={"book.form.total.people"} value={formData.num_people} />
        <div className="book-form-horizontal-divider"></div>
        <div className="book-form-prive-field">
          <BookFormRightField
            label={"book.form.total.price"}
            value={`${getTotalPrice(
              selectedRoutePrice,
              totalPrice(formData, selectedRoute, selectedPackage),
              getTotalPriceExtraNights(selectedPackage.extra_night || 0, formData.extra_nights || 0),
              formData.num_people
            )} €`}
          />
          <div className="book-form-price-subtitle">
            {selectedPackage !== null
              ? `${getPrice(
                  selectedRoutePrice,
                  totalPrice(formData, selectedRoute, selectedPackage),
                  getTotalPriceExtraNights(selectedPackage.extra_night || 0, formData.extra_nights || 0)
                )} € x nº ${t("book.form.people")}`
              : "___€"}
          </div>
        </div>
        <button
          type="submit"
          className={`book-form-right-content-submit ${isFormValid ? "active" : "disabled"}`}
          onClick={() => {
            if (isFormValid) {
              onBookSubmit(
                formData,
                closeForm,
                showNotification,
                getTotalPrice(
                  selectedPackage.price,
                  totalPrice(formData),
                  getTotalPriceExtraNights(selectedPackage.extra_night || 0, formData.extra_nights || 0),
                  formData.num_people
                ),
                t,
                currentLang
              );
              handleCheckout(false);
            }
          }}
        >
          {t("book.form.button.pay")}
        </button>
        <button
          type="submit"
          className={`book-form-right-content-submit ${has20PersentAble(formData) ? "active" : "disabled"}`}
          onClick={() => {
            if (isFormValid && has20PersentAble(formData)) {
              onBookSubmit(
                formData,
                closeForm,
                showNotification,
                getTotalPartPrice(
                  selectedPackage.price,
                  totalPrice(formData),
                  getTotalPriceExtraNights(selectedPackage.extra_night || 0, formData.extra_nights || 0),
                  formData.num_people
                ),
                t,
                currentLang
              );
              handleCheckout(true);
            }
          }}
        >
          {t("book.form.button.pay.part")}
        </button>
        <p className={`bookk-form-budget-button`} title={t("book.form.button.part.info.tooltip")}>
          {t("book.form.button.part.info")}
        </p>
        <p className={`bookk-form-budget-button-real`}>
          <Link className={`bookk-form-budget-button-real`} to="/politica-de-cancelacion-reembolso">
            {t("book.form.button.workinf.pay.info")}
          </Link>
        </p>
        <p className={`bookk-form-budget-button`}>{t("book.form.button.workinf.pay.tax")}</p>
        {/* {isFormValid && (
        <p className={`bookk-form-budget-button`} onClick={() => alert("Working on it...")}>
          presupuesto personalizado
        </p>
      )} */}
      </div>
    </Elements>
  );
};

export default BookFormRight;
