import React, { useState } from "react";
import "./services-info.css";
import { FaCheck } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import default_img from "../../public/paquete.jpg";
import { useMediaQuery } from "react-responsive";

const ServicesInfoIncluded = ({ basicIncludedServices, classicIncludedServices }) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const bothPacksServicesIncluded = [14, 15, 16, 17, 18, 21];
  return (
    <div className="services-info-included">
      <div className="sevices-info-included-title">{t("services.info.inclluded")}</div>
      <div className="services-info-only-container">
        <h3 className="sevices-info-kind-title">{t("services.info.inclluded.classic")}</h3>
        {classicIncludedServices?.map((service) => {
          const description = currentLang === "en" ? service.description_en : service.description;
          const serviceName = currentLang === "en" ? service.name_en : service.name;
          return (
            !bothPacksServicesIncluded.includes(service.id) && (
              <div className="services-info-kind-content">
                <div className="services-info-kind-left">
                  <div key={service.id} className="services-info-kind-list-title">
                    <FaCheck className="services-info-kind-list-check-icon" />
                    <div className="services-info-kind-list-check-title">{serviceName}</div>
                  </div>
                  {isMobile ? (
                    <>
                      <div className={`services-info-kind-list-description ${isExpanded ? "expanded" : ""}`}>{description}</div>
                      <button className="toggle-button-services" onClick={toggleExpand} aria-expanded={isExpanded}>
                        {isExpanded ? "Mostrar menos" : "Mostrar más"}
                      </button>
                    </>
                  ) : (
                    <div className="services-info-kind-list-description">{description}</div>
                  )}
                </div>
                <div className="services-info-kind-right">
                  <img src={service.url_photo || default_img} alt="Logo GaliRoutes" className="aux-info-image" />
                </div>
              </div>
            )
          );
        })}
      </div>
      <div className="services-info-only-container">
        <h3 className="sevices-info-kind-title">{t("services.info.inclluded.basic")}</h3>
        {basicIncludedServices?.map((service) => {
          const description = currentLang === "en" ? service.description_en : service.description;
          const serviceName = currentLang === "en" ? service.name_en : service.name;
          return (
            !bothPacksServicesIncluded.includes(service.id) && (
              <div className="services-info-kind-content">
                <div className="services-info-kind-left">
                  <div key={service.id} className="services-info-kind-list-title">
                    <FaCheck className="services-info-kind-list-check-icon" />
                    <div className="services-info-kind-list-check-title">{serviceName}</div>
                  </div>
                  {isMobile ? (
                    <>
                      <div className={`services-info-kind-list-description ${isExpanded ? "expanded" : ""}`}>{description}</div>
                      <button className="toggle-button-services" onClick={toggleExpand} aria-expanded={isExpanded}>
                        {isExpanded ? "Mostrar menos" : "Mostrar más"}
                      </button>
                    </>
                  ) : (
                    <div className="services-info-kind-list-description">{description}</div>
                  )}
                </div>
                <div className="services-info-kind-right">
                  <img src={service.url_photo || default_img} alt="Logo GaliRoutes" className="aux-info-image" />
                </div>
              </div>
            )
          );
        })}
      </div>
      <div className="services-info-only-container">
        <h3 className="sevices-info-kind-title">{t("services.info.extra.both")}</h3>
        {basicIncludedServices?.map((service) => {
          const description = currentLang === "en" ? service.description_en : service.description;
          const serviceName = currentLang === "en" ? service.name_en : service.name;
          return (
            bothPacksServicesIncluded.includes(service.id) && (
              <div className="services-info-kind-content">
                <div className="services-info-kind-left">
                  <div key={service.id} className="services-info-kind-list-title">
                    <FaCheck className="services-info-kind-list-check-icon" />
                    <div className="services-info-kind-list-check-title">{serviceName}</div>
                  </div>
                  {isMobile ? (
                    <>
                      <div className={`services-info-kind-list-description ${isExpanded ? "expanded" : ""}`}>{description}</div>
                      <button className="toggle-button-services" onClick={toggleExpand} aria-expanded={isExpanded}>
                        {isExpanded ? "Mostrar menos" : "Mostrar más"}
                      </button>
                    </>
                  ) : (
                    <div className="services-info-kind-list-description">{description}</div>
                  )}
                </div>
                <div className="services-info-kind-right">
                  <img src={service.url_photo || default_img} alt="Logo GaliRoutes" className="aux-info-image" />
                </div>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default ServicesInfoIncluded;
