import React, { useState } from "react";
import "./services-info.css";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import default_img from "../../public/paquete.jpg";

const ServicesInfoExtra = ({ basicExtraServices, classicExtraServices }) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const justClassicExtraServices = [3, 20];
  return (
    <div className="services-info-included">
      <div className="sevices-info-included-title">{t("services.info.extra")}</div>
      <div className="services-info-only-classic-container">
        <h3 className="sevices-info-kind-title-classic">{t("services.info.extra.classic")}</h3>
        {classicExtraServices?.map((service) => {
          const description = currentLang === "en" ? service.description_en : service.description;
          const serviceName = currentLang === "en" ? service.name_en : service.name;
          return (
            justClassicExtraServices.includes(service.id) && (
              <div key={service.id} className="services-info-kind-content-classic">
                <div className="services-info-kind-left-classic">
                  <div>
                    <div className="services-info-kind-list-title-classic">
                      <div className="services-info-kind-list-price-classic">
                        {t("package.price.from")} {Math.round(service.price)}€
                      </div>
                      <div className="services-info-kind-list-check-title-classic">{serviceName}</div>
                    </div>
                    {isMobile ? (
                      <>
                        <div className={`services-info-kind-list-description-classic ${isExpanded ? "expanded" : ""}`}>{description}</div>
                        <button className="toggle-button-services-classic" onClick={toggleExpand} aria-expanded={isExpanded}>
                          {isExpanded ? "Mostrar menos" : "Mostrar más"}
                        </button>
                      </>
                    ) : (
                      <div className="services-info-kind-list-description-classic">{description}</div>
                    )}
                  </div>
                </div>
                <div className="services-info-kind-right-classic">
                  <img src={service.url_photo || default_img} alt="Logo GaliRoutes" className="services-info-image-classic" />
                </div>
              </div>
            )
          );
        })}
      </div>
      <div className="services-info-only-container">
        <h3 className="sevices-info-kind-title">{t("services.info.extra.both")}</h3>
        {basicExtraServices?.map((service) => {
          const description = currentLang === "en" ? service.description_en : service.description;
          const serviceName = currentLang === "en" ? service.name_en : service.name;
          return (
            <div key={service.id} className="services-info-kind-content">
              <div className="services-info-kind-left">
                <div className="services-info-kind-list-title">
                  <div className="services-info-kind-list-price">
                    {t("package.price.from")} {Math.round(service.price)}€
                  </div>
                  <div className="services-info-kind-list-check-title">{serviceName}</div>
                </div>
                {isMobile ? (
                  <>
                    <div className={`services-info-kind-list-description ${isExpanded ? "expanded" : ""}`}>{description}</div>
                    <button className="toggle-button-services" onClick={toggleExpand} aria-expanded={isExpanded}>
                      {isExpanded ? "Mostrar menos" : "Mostrar más"}
                    </button>
                  </>
                ) : (
                  <div className="services-info-kind-list-description">{description}</div>
                )}
              </div>
              <div className="services-info-kind-right">
                <img src={service.url_photo || default_img} alt="Logo GaliRoutes" className="services-info-image" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ServicesInfoExtra;
