import React, { useEffect } from "react";
import "./layout.css";
import Header from "./header/Header";
import Home from "../home/Home";
import PackageModule from "../components/package/PackageModule";
import Routes from "../components/routes/Routes";
import Footer from "./footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const handleReload = (event) => {
      sessionStorage.setItem("reloaded", "true");
    };

    window.addEventListener("beforeunload", handleReload);

    return () => {
      window.removeEventListener("beforeunload", handleReload);
    };
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("reloaded")) {
      sessionStorage.removeItem("reloaded");

      if (window.location.pathname !== "/") {
        window.history.replaceState(null, "", "/");
      }
    }
  }, []);

  return (
    <div className="container">
      <Header />
      <main className="content">
        <Home />
        <PackageModule />
        <Routes />
        <Footer />
      </main>
    </div>
  );
};

export default Layout;
