import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ServiceInfoModal = ({ closeServiceModal, type }) => {
  const { t } = useTranslation();
  return (
    <div className="service-info-modal-overlay">
      <div className="service-info-modal-container" onClick={(e) => e.stopPropagation()}>
        <Link to="/">
          <button className="services-info-close-icon" onClick={closeServiceModal} aria-label="Cerrar">
            &times;
          </button>
        </Link>
        <h1 className="services-info-modal-title" style={{ textAlign: "left" }}>
          {t(`service.info.title.${type}`)}
        </h1>
        <p className="service-info-modal-intro">{t(`service.info.description.${type}`)}</p>
        <h1 className="services-info-modal-title" style={{ textAlign: "left" }}>
          {t(`service.info.subtitle.${type}`)}
        </h1>
        <p className="service-info-modal-intro">
          {t(`service.info.description.p1.${type}`)} <strong>{t(`service.info.description.p2.${type}`)}</strong>{" "}
          {t(`service.info.description.p3.${type}`)}
        </p>
      </div>
    </div>
  );
};

export default ServiceInfoModal;
