import { PRO_URL } from "../../../api";

export const getPrice = (pkg, extraServices, extraNights) => {
  const total = [pkg, extraServices, extraNights].reduce(
    (sum, value) => sum + (parseFloat(value) || 0), // Convierte a número y maneja `null` o `undefined`
    0
  );

  return `${total.toFixed(2)}`; // Redondea a 2 decimales
};

export const getBookRoutePrice = (packageId, selectedRoute) => {
  return packageId === 1 ? Math.round(selectedRoute?.price_classic) : Math.round(selectedRoute?.price_basic);
};

export const getTotalPrice = (pkg, extraServices, extraNights, num_people) => {
  const total = getPrice(pkg, extraServices, extraNights) * (num_people || 1);
  return `${Math.round(total)}`;
};

export const getTotalPartPrice = (pkg, extraServices, extraNights, num_people) => {
  const total = getPrice(pkg, extraServices, extraNights) * (num_people || 1);
  return `${Math.round(total * 0.2)}`;
};

export const getTotalStripePrice = (pkg, extraServices, extraNights, num_people) => {
  // Obtener el total base (sin multiplicar por la cantidad de personas)
  const total = getPrice(pkg, extraServices, extraNights) * (num_people || 1);

  // Convertir a centavos y devolver como un número entero
  return Math.round(total * 100); // Multiplicamos por 100 y redondeamos para centavos
};

export const getTotalPartStripePrice = (pkg, extraServices, extraNights, num_people) => {
  // Obtener el total base (sin multiplicar por la cantidad de personas)
  const total = getPrice(pkg, extraServices, extraNights) * (num_people || 1);

  // Convertir a centavos y devolver como un número entero
  return Math.round(total * 0.2 * 100); // Multiplicamos por 100 y redondeamos para centavos
};

export const getTotalDays = (initial, extra) => {
  return initial + Number(extra);
};

export const getTotalPriceExtraNights = (price, num_nights) => {
  const total = price * num_nights;

  return `${Math.round(total)}`;
};

export const calculateEndDate = (startDate, numDays) => {
  // Convierte la fecha de inicio a un objeto Date
  const start = new Date(startDate);

  // Sumar los días al objeto Date
  start.setDate(start.getDate() + numDays);

  // Formatear la fecha de fin en formato 'dd / mm / yyyy'
  const day = String(start.getDate()).padStart(2, "0") || "00";
  const month = String(start.getMonth() + 1).padStart(2, "0") || "00"; // Los meses empiezan desde 0
  const year = start.getFullYear() || "0000";

  // Devuelve la fecha formateada
  return `${day} / ${month} / ${year}`;
};

export const totalPrice = (formData, route, pckg) => {
  return formData.extraServices.reduce((sum, service) => {
    const price = getPricePerExtraService(service.price, service.id, route?.stages.length, route?.id, pckg?.id, formData.num_people) || 0;

    return Math.round(sum + parseFloat(price));
  }, 0);
};

export const getPricePerExtraService = (price, serviceId, routeNumStages, routeId, packageId, numPeople) => {
  const pricesPerNight = [1, 2, 3];
  if (pricesPerNight.includes(serviceId)) return Math.round(price * routeNumStages);
  if (routeId === 3) return serviceId === 4 ? getPriceFourPeople(250, numPeople) : 180;
  if (routeId === 5 && serviceId === 4) return getPriceFourPeople(300, numPeople);
  if (serviceId === 5) return getPriceFourPeople(price, numPeople);
  if (serviceId === 4) return getPriceFourPeople(price, numPeople);
  // if (pricesPerRoute.includes(serviceId)) return getPricePerRoute(routeId, serviceId, packageId);
  else return Math.round(price);
};

const getPriceFourPeople = (price, numPeople) => {
  return Math.round(price * Math.ceil(numPeople / 4));
};
// export const getPackageServices = async (packageId) => {
//   try {
//     const response = await api.get(`/packages/${packageId}/services/`);
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching package services:", error);
//     throw error;
//   }
// };

export const showServiceExtra = (serviceId, routeId) => {
  if (serviceId === 8 && routeId === 4) return false;
  if (serviceId === 4 && routeId === 8) return false;
  else return true;
};

export const handleServiceSelection = (service, formData, setFormData) => {
  const updatedServices = [...formData.extraServices];

  const serviceIndex = updatedServices.findIndex((s) => s.name === service.name);

  if (serviceIndex !== -1) {
    updatedServices.splice(serviceIndex, 1);
  } else {
    updatedServices.push(service);
  }

  setFormData((prev) => ({
    ...prev,
    extraServices: updatedServices,
  }));
};

// Obtener fecha actual en formato yyyy-mm-dd
export const today = new Date().toISOString().split("T")[0];

export const validateForm = (formData, t, setErrors) => {
  const newErrors = {};

  // Validar nombre
  if (!formData.name || formData.name.trim() === "") {
    newErrors.name = t("book.form.errors.required");
  }

  // Validar email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!formData.email || !emailRegex.test(formData.email)) {
    newErrors.email = t("book.form.errors.invalidEmail");
  }

  // Validar teléfono
  const phoneRegex = /^\+\d{1,4}\d{7,15}$/; // +prefijo seguido de 7-15 dígitos
  if (!formData.phone || !phoneRegex.test(formData.phone)) {
    newErrors.phone = t("book.form.errors.invalidPhone");
  }

  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

export const handlePhoneChange = (e, setFormData) => {
  const value = e.target.value;
  // Añadir prefijo por defecto si no está presente
  if (!value.startsWith("+")) {
    setFormData((prev) => ({
      ...prev,
      phone: "+34" + value,
    }));
  } else {
    handleInputChange(e);
  }
};

export const onBookSubmit = async (userInfo, closeForm, showNotification, price, t, language) => {
  console.log(userInfo);
  const bookPetitionUrl = language === "en" ? `${PRO_URL}/api/send-reservation-form-en/` : `${PRO_URL}/api/send-reservation-form/`;
  try {
    const response = await fetch(bookPetitionUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        client_name: userInfo.name,
        client_email: userInfo.email,
        client_number: userInfo.phone,
        package: userInfo.package,
        route: userInfo.route,
        start_date: userInfo.start_date,
        extra_nights: userInfo.extra_nights,
        total_people: userInfo.num_people,
        additional_services: userInfo.extraServices.map((service) => service.name), // Asegúrate de manejar el caso en el que no haya servicios adicionales
        total_price: parseInt(price),
        paid: false,
      }),
    });

    if (response.ok) {
      //showNotification(`${t("book.form.success")}`, "success");
      closeForm(); // Cerrar el formulario al enviar
    } else {
      const errorData = await response.json();
      showNotification(`ERROR: ${errorData.error}`, "error");
    }
  } catch (error) {
    console.error("Error al enviar el formulario:", error);
    showNotification(`${t("book.form.eror")}`, "error");
  }
};

export const getPricePerRoute = async (routeId, serviceId, packageId) => {
  const BASE_URL = `${PRO_URL}/api`;
  const endpoint = `${BASE_URL}/service-price/${packageId}/${routeId}/${serviceId}`;

  try {
    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      // Manejo detallado de errores HTTP
      const errorDetails = await response.json();
      console.error("Error en la respuesta del servidor:", errorDetails);
      throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`);
    }

    // Parsear y devolver la respuesta si todo salió bien
    const data = await response.json();
    return data.price;
  } catch (error) {
    // Manejo de errores de red u otros
    console.error("Error al obtener el precio por ruta:", error.message);
    throw error; // Propaga el error para manejarlo en niveles superiores si es necesario
  }
};

export const handleInputChange = (e, setCharCount) => {
  setCharCount(e.target.value.length);
};

export const getPrices = (price, serviceId, routeNumStages, routeId, packageId) => {
  const pricesPerNight = [1, 2, 3];
  if (pricesPerNight.includes(serviceId)) return Math.round(price * routeNumStages);
  if (routeId === 3) return serviceId === 4 ? 250 : 180;
  if (routeId === 5 && serviceId === 4) return 300;
  // if (pricesPerRoute.includes(serviceId)) return getPricePerRoute(routeId, serviceId, packageId);
  else return Math.round(price);
};

export const has20PersentAble = (formData) => {
  const startDate = new Date(formData.start_date); // Convertir a objeto Date
  const today = new Date(); // Fecha actual
  const oneMonthLater = new Date();
  oneMonthLater.setMonth(today.getMonth() + 1); // Sumar un mes

  // Validar si start_date está dentro del rango
  return startDate >= oneMonthLater;
};
