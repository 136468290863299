import React, { useEffect, useState } from "react";
import "./Package.css";
import Package from "./Package";
import PackageForm from "./PackageForm";
import { useTranslation } from "react-i18next";
import { useBooking } from "../../providers/BookProvider";
import { PRO_URL } from "../../api";

const PackageModule = ({ isForm }) => {
  const { t } = useTranslation();
  const { setSelectedPackage } = useBooking();
  const [packages, setPackages] = useState([]); // Estado para los datos
  const [loading, setLoading] = useState(true); // Estado para indicar carga
  const [error, setError] = useState(null); // Estado para manejar errores

  useEffect(() => {
    // Función para obtener los paquetes
    const fetchPackages = async () => {
      try {
        const response = await fetch(`${PRO_URL}/api/packages/`);
        if (!response.ok) {
          throw new Error(`Error en la solicitud: ${response.status}`);
        }
        const data = await response.json(); // Parsear los datos
        setPackages(data); // Guardar los datos en el estado
        setSelectedPackage(data[0]);
      } catch (err) {
        setError(err.message); // Capturar el error
      } finally {
        setLoading(false); // Marcar la carga como completada
      }
    };

    fetchPackages();
  }, []); // [] asegura que se ejecuta solo al montar el componente

  // Mostrar mensaje mientras los datos cargan
  if (loading) return <p>{t("api.response.loading")}</p>;

  // Mostrar error si ocurre
  if (error)
    return (
      <p>
        {t("api.response.error")}
        {error}
      </p>
    );

  return (
    <div className={`package-container ${isForm}`}>
      {packages.map((pckg) => (
        <div className={`package-wrapper ${isForm}`} key={pckg.id}>
          {!isForm && <Package pckg={pckg} isForm={isForm} />}
          {isForm && <PackageForm pckg={pckg} isForm={isForm} />}
        </div>
      ))}
    </div>
  );
};

export default PackageModule;
